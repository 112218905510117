.main {
  max-width: 10rem;
  margin: auto;
}
.bg {
  position: relative;
  width: 100%;
  height: 5rem;
  background: url("https://qy-rc.vivadyw.com/vivadyw/bg.png") no-repeat;
  background-size: 100%;
  background-color: #000;
}

.content {
  /* text-align: center; */
  position: absolute;
  left: 56%;
  top: 1rem;
  z-index: 999;
  width: 3.1rem;
  height: 2.125rem;
}

.phone {
  position: absolute;
  left: 2.1875rem;
  bottom: 0;
  width: 2.8rem;
}

.icon {
  position: absolute;
  /* top: 1.3125rem; */

  height: .703125rem;
  width: .703125rem;
}


.title-h2 {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 32%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: .25rem;
}

.appleTarget {
  position: absolute;
  width: 2.125rem;
  height: 0.614583rem;
  left: 0px;
  bottom: 0.104167rem;
}

.google {
  position: absolute;
  width: 2.125rem;
  height: 0.614583rem;
  right: 0;
  bottom: 0.104167rem;
}

.appleImg,
.googleImg {
  position: absolute;
  bottom: 0;
  width: .84375rem;
}

.imgs img {
  width: 100%;
  vertical-align: middle;
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 1.197917rem;
  font-size: 0.145833rem;
  color: #ffffff;
  background-color: #313131;
}

.footer a {
  text-decoration: none;
  color: #ffffff;
}

.contact {
  position: relative;

  display: block;
  padding-top: 0.15625rem;
  padding-bottom: 0.15625rem;
}

.contact:hover .pop {
  display: block;
}

.contact:hover {
  cursor: pointer;
}

.pop:hover {
  display: block;
}
.pop::after {
  content: "";
  position: absolute;
  bottom: -0.052083rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 0.052083rem solid transparent;
  border-right: 0.052083rem solid transparent;
  border-top: 0.052083rem solid #fff;
}

.pop {
  display: none;
  position: absolute;
  left: -0.85rem;
  top: -0.85rem;
  width: 1.854167rem;
  color: #000;
  font-size: 0.135417rem;
  padding: 0.151042rem 0.260417rem;
  background-color: #fff;
  border-radius: 0.052083rem;
  z-index: 999;
}

.pop span {
  color: rgb(63, 187, 236);
}

.remark {
  height: 60px;
  line-height: 60px;
  text-align: center;
  /* background-color: #fff; */
  /* border-top: 1px solid #000; */
  font-size:16px;
  color: #fff;
}

.remark a {
  text-decoration:none;
  color: #424AC9;
}